import * as React from 'react';
import { colors } from '../sidebar/utils/presets';

class InlineCode extends React.PureComponent {
  render() {
    return (
      <code
        style={{
          backgroundColor: colors.gray.superLight,
          borderRadius: `3px`,
        }}
      >
        {this.props.children}
      </code>
    );
  }
}

export default InlineCode;
