import { Table } from 'semantic-ui-react';

import { H1, H2, H3, H4, H5, H6 } from './header';
import Highlighter from './highlighter';
import InlineCode from './inline_code';

export const mdxComponents = {
  table: Table,
  code: Highlighter,
  inlineCode: InlineCode,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
};
