import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { MDXProvider } from '@mdx-js/react';

import { mdxComponents } from '../components/mdx_blocks';

import { Message } from 'semantic-ui-react';

import { versionNumberFromDocPath } from '../utils';

import 'katex/dist/katex.min.css';

import { withDocLayout } from '../components/layout';

interface MDXPageTemplateProps {
  path: string;
  data: {
    mdx: {
      frontmatter: {
        title: string;
        images: {
          childImageSharp: {
            gatsbyImageData: {
              width: number;
            };
          };
        }[];
      };
      body: string;
    };
    site: {
      siteMetadata: {
        salvusDocVersions: {
          current: string;
        };
      };
    };
  };
}

class MDXPageTemplate extends React.Component<
  MDXPageTemplateProps & { location: { pathname: string } },
  {}
> {
  convertImagesToComponents() {}

  public render() {
    // Version string. Either the number or "LATEST" for the latest version.
    let version = versionNumberFromDocPath(this.props.location.pathname);
    if (
      version == this.props.data.site.siteMetadata.salvusDocVersions.current
    ) {
      version = 'LATEST';
    }

    let images = this.props.data.mdx.frontmatter.images;
    if (images == undefined) {
      images = [];
    }

    const imgs: { [k: string]: React.ReactNode } = {};

    if (images) {
      images.forEach((image, i) => {
        imgs[`Img${i + 1}`] = () => (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt=""
            style={{
              display: 'block',
              margin: '30px auto',
              maxWidth: image.childImageSharp.gatsbyImageData.width,
            }}
          />
        );
      });
    }

    return (
      <div ref="mdx">
        <MDXProvider components={mdxComponents}>
          {/* Display the warning message if we are currently not viewing the latest version. */}
          {version != 'LATEST' &&
            // Also don't show it for a number of other paths.
            !['examples', 'references', 'publications'].includes(
              this.props.location.pathname.split('/')[3]
            ) && (
              <Message
                size="mini"
                color="orange"
                content={
                  <div>
                    <p>
                      This page here has been created for the latest stable
                      release of Salvus. You have chosen to view the
                      documentation for another Salvus version. Please be aware
                      there might be some small differences you have to account
                      for.
                    </p>
                  </div>
                }
              />
            )}
          {/* But show another message for the examples and the reference. */}
          {version != 'LATEST' &&
            // Also don't show it for a number of other paths.
            ['examples', 'references'].includes(
              this.props.location.pathname.split('/')[3]
            ) && (
              <Message
                size="mini"
                color="orange"
                content={
                  <div>
                    <p>
                      This documentation is not for the latest stable Salvus
                      version.
                    </p>
                  </div>
                }
              />
            )}
          <MDXRenderer location={this.props.location} images={imgs}>
            {this.props.data.mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </div>
    );
  }

  public componentDidMount() {
    let pageLinks = [];
    for (let node of this.refs.mdx.getElementsByTagName('a')) {
      if (!node.id) {
        continue;
      }
      pageLinks.push({
        link: `#${node.id}`,
        level: parseInt(node.parentNode.tagName.slice(1)),
        text: node.nextSibling.text,
      });
    }

    // Call a function on the parent to update the table of contents.
    this.props.updateTableOfContentsFct(pageLinks);
  }

  public componentDidUpdate() {
    this.componentDidMount();
  }
}

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        images {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 600, placeholder: TRACED_SVG)
          }
        }
      }
      body
    }
    site {
      siteMetadata {
        salvusDocVersions {
          current
        }
      }
    }
  }
`;

export default withDocLayout(MDXPageTemplate);
